var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-content" },
    [
      _c("h2", { staticClass: "h4" }, [_vm._v("01. Account settings")]),
      _c("h6", { staticClass: "mt-5 mb-5" }, [_vm._v("Profile")]),
      _vm.account && _vm.account.name
        ? _c(
            "b-form",
            {
              staticClass: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveAccount($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { description: "Enter your real name here" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text", placeholder: "Your Name*" },
                          model: {
                            value: _vm.account.name,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "name", $$v)
                            },
                            expression: "account.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { description: "Enter your email" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text", placeholder: "Email*" },
                          model: {
                            value: _vm.account.email,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "email", $$v)
                            },
                            expression: "account.email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          description:
                            "People will see this username on the platform"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            disabled: "",
                            placeholder: "Username*"
                          },
                          model: {
                            value: _vm.account.steam.displayName,
                            callback: function($$v) {
                              _vm.$set(_vm.account.steam, "displayName", $$v)
                            },
                            expression: "account.steam.displayName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group select-wrapper" },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: "",
                          "value-field": "code",
                          "text-field": "name",
                          options: _vm.options
                        },
                        model: {
                          value: _vm.account.country,
                          callback: function($$v) {
                            _vm.$set(_vm.account, "country", $$v)
                          },
                          expression: "account.country"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-group d-sm-flex justify-content-sm-between align-items-sm-center"
                },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "secondary" } },
                    [_vm._v("Save Changes")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c("h6", { staticClass: "mt-5 mb-5" }, [_vm._v("Change password")]),
      _c(
        "b-form",
        {
          staticClass: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.changePassword($event)
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-form-group",
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "password",
                        placeholder: "Current Password"
                      },
                      model: {
                        value: _vm.oldPassword,
                        callback: function($$v) {
                          _vm.oldPassword = $$v
                        },
                        expression: "oldPassword"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-form-group",
                  [
                    _c("b-form-input", {
                      attrs: { type: "password", placeholder: "New Password" },
                      model: {
                        value: _vm.newPassword,
                        callback: function($$v) {
                          _vm.newPassword = $$v
                        },
                        expression: "newPassword"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-form-group",
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "password",
                        placeholder: "Repeat Password"
                      },
                      model: {
                        value: _vm.newPasswordConfirm,
                        callback: function($$v) {
                          _vm.newPasswordConfirm = $$v
                        },
                        expression: "newPasswordConfirm"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass:
                "form-group d-sm-flex justify-content-sm-between align-items-sm-center"
            },
            [
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "secondary" } },
                [_vm._v("Change Password")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }