<template>
  <div class="account-content">
		<h2 class="h4">01. Account settings</h2>
		<h6 class="mt-5 mb-5">Profile</h6>
		<b-form @submit.prevent="saveAccount" class="form" v-if="account && account.name">
			<div class="row">
				<div class="col-md-6">
					<b-form-group description="Enter your real name here">
						<b-form-input
							v-model="account.name"
							type="text"
							placeholder="Your Name*" />
					</b-form-group>
				</div>
				<div class="col-md-6">
					<b-form-group description="Enter your email">
						<b-form-input
							v-model="account.email"
							type="text"
							placeholder="Email*" />
					</b-form-group>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<b-form-group description="People will see this username on the platform">
						<b-form-input
							v-model="account.steam.displayName"
							type="text"
							disabled
							placeholder="Username*" />
					</b-form-group>
				</div>
				<div class="col-md-6">
					<div class="form-group select-wrapper">
						<b-form-select plain v-model="account.country" value-field="code" text-field="name" :options="options"></b-form-select>
					</div>

				</div>
			</div>
			<div class="form-group d-sm-flex justify-content-sm-between align-items-sm-center">
				<b-button type="submit" variant="secondary">Save Changes</b-button>
			</div>
		</b-form>
		<h6 class="mt-5 mb-5">Change password</h6>
		<b-form @submit.prevent="changePassword" class="form">
					<div class="row">
						<div class="col-md-4">
							<b-form-group>
								<b-form-input
									v-model="oldPassword"
									type="password"
									placeholder="Current Password" />
							</b-form-group>
						</div>
						<div class="col-md-4">
							<b-form-group>
								<b-form-input
									v-model="newPassword"
									type="password"
									placeholder="New Password" />
							</b-form-group>
						</div>
						<div class="col-md-4">
							<b-form-group>
								<b-form-input
									v-model="newPasswordConfirm"
									type="password"
									placeholder="Repeat Password" />
							</b-form-group>
						</div>
					</div>
					<div class="form-group d-sm-flex justify-content-sm-between align-items-sm-center">
						<b-button type="submit" variant="secondary">Change Password</b-button>
					</div>
		</b-form>
	</div>
</template>

<script>
import countries from '@/data/countries';
import cloneDeep from 'lodash.clonedeep';

export default {
	data() {
		return {
			oldPassword: '',
			newPassword: '',
			newPasswordConfirm: '',
			options: countries,
		};
	},
	methods: {
		async changePassword() {
			await this.$api.account.changePassword(this.oldPassword, this.newPassword);
		},
		async saveAccount() {
			await this.$store.dispatch('updateUserAccount', { email: this.account.email, name: this.account.name, country: this.account.country });
			this.$toasted.info('Account updated successfully');
		},
	},
	computed: {
		account() {
			const clonedAccount = cloneDeep(this.$store.state.userAccount);
			return clonedAccount;
		},
	},
};
</script>

<style>

</style>
