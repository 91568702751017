module.exports = [
	{ name: 'Afghanistan', code: 'AF' },
	{ name: 'Albania', code: 'AL' },
	{ name: 'Algeria', code: 'DZ' },
	{ name: 'Argentina', code: 'AR' },
	{ name: 'Armenia', code: 'AM' },
	{ name: 'Australia', code: 'AU' },
	{ name: 'Austria', code: 'AT' },
	{ name: 'Azerbaijan', code: 'AZ' },
	{ name: 'Bahamas', code: 'BS' },
	{ name: 'Bangladesh', code: 'BD' },
	{ name: 'Belarus', code: 'BY' },
	{ name: 'Belgium', code: 'BE' },
	{ name: 'Bolivia', code: 'BO' },
	{ name: 'Bosnia and Herzegovina', code: 'BA' },
	{ name: 'Brazil', code: 'BR' },
	{ name: 'Bulgaria', code: 'BG' },
	{ name: 'Cameroon', code: 'CM' },
	{ name: 'Canada', code: 'CA' },
	{ name: 'Central African Republic', code: 'CF' },
	{ name: 'Chile', code: 'CL' },
	{ name: 'China', code: 'CN' },
	{ name: 'Colombia', code: 'CO' },
	{ name: 'Costa Rica', code: 'CR' },
	{ name: 'Croatia', code: 'HR' },
	{ name: 'Cuba', code: 'CU' },
	{ name: 'Cyprus', code: 'CY' },
	{ name: 'Czech Republic', code: 'CZ' },
	{ name: 'Denmark', code: 'DK' },
	{ name: 'Ecuador', code: 'EC' },
	{ name: 'Egypt', code: 'EG' },
	{ name: 'Estonia', code: 'EE' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'France', code: 'FR' },
	{ name: 'Georgia', code: 'GE' },
	{ name: 'Germany', code: 'DE' },
	{ name: 'Ghana', code: 'GH' },
	{ name: 'Greece', code: 'GR' },
	{ name: 'Hong Kong', code: 'HK' },
	{ name: 'Hungary', code: 'HU' },
	{ name: 'Iceland', code: 'IS' },
	{ name: 'India', code: 'IN' },
	{ name: 'Indonesia', code: 'ID' },
	{ name: 'Ireland', code: 'IE' },
	{ name: 'Israel', code: 'IL' },
	{ name: 'Italy', code: 'IT' },
	{ name: 'Jamaica', code: 'JM' },
	{ name: 'Japan', code: 'JP' },
	{ name: 'Kazakhstan', code: 'KZ' },
	{ name: 'Kenya', code: 'KE' },
	{ name: 'Kuwait', code: 'KW' },
	{ name: 'Latvia', code: 'LV' },
	{ name: 'Liechtenstein', code: 'LI' },
	{ name: 'Lithuania', code: 'LT' },
	{ name: 'Luxembourg', code: 'LU' },
	{ name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
	{ name: 'Malaysia', code: 'MY' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Mexico', code: 'MX' },
	{ name: 'Moldova, Republic of', code: 'MD' },
	{ name: 'Monaco', code: 'MC' },
	{ name: 'Mongolia', code: 'MN' },
	{ name: 'Morocco', code: 'MA' },
	{ name: 'Netherlands', code: 'NL' },
	{ name: 'New Zealand', code: 'NZ' },
	{ name: 'Nicaragua', code: 'NI' },
	{ name: 'Niger', code: 'NE' },
	{ name: 'Nigeria', code: 'NG' },
	{ name: 'Niue', code: 'NU' },
	{ name: 'Norway', code: 'NO' },
	{ name: 'Pakistan', code: 'PK' },
	{ name: 'Panama', code: 'PA' },
	{ name: 'Paraguay', code: 'PY' },
	{ name: 'Peru', code: 'PE' },
	{ name: 'Philippines', code: 'PH' },
	{ name: 'Poland', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Puerto Rico', code: 'PR' },
	{ name: 'Qatar', code: 'QA' },
	{ name: 'Romania', code: 'RO' },
	{ name: 'Russian Federation', code: 'RU' },
	{ name: 'San Marino', code: 'SM' },
	{ name: 'Saudi Arabia', code: 'SA' },
	{ name: 'Serbia and Montenegro', code: 'CS' },
	{ name: 'Sierra Leone', code: 'SL' },
	{ name: 'Singapore', code: 'SG' },
	{ name: 'Slovakia', code: 'SK' },
	{ name: 'Slovenia', code: 'SI' },
	{ name: 'Somalia', code: 'SO' },
	{ name: 'South Africa', code: 'ZA' },
	{ name: 'Spain', code: 'ES' },
	{ name: 'Swaziland', code: 'SZ' },
	{ name: 'Sweden', code: 'SE' },
	{ name: 'Switzerland', code: 'CH' },
	{ name: 'Taiwan, Province of China', code: 'TW' },
	{ name: 'Tunisia', code: 'TN' },
	{ name: 'Turkey', code: 'TR' },
	{ name: 'Uganda', code: 'UG' },
	{ name: 'Ukraine', code: 'UA' },
	{ name: 'United Arab Emirates', code: 'AE' },
	{ name: 'United Kingdom', code: 'GB' },
	{ name: 'United States', code: 'US' },
	{ name: 'Uruguay', code: 'UY' },
	{ name: 'Venezuela', code: 'VE' },
	{ name: 'Viet Nam', code: 'VN' },
];
